import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import CardContainer from 'layouts/CardContainer'
import Container from 'containers/Container'
import styles from './Resources.module.scss'
import { PickFirst } from 'services/utils'
import { FormatTags } from '../../services/utils'
import Pagination from 'components/Pagination'
import { useRouter } from 'next/router'

const GetImage = (data) => {
  const check = (key) => {
    if (data[key] && data[key].length > 0) {
      return data[key][0]
    }
  }

  const featured = check('featuredImage')
  if (featured) {
    if (Array.isArray(featured)) {
      return featured[0] || {}
    }

    return featured || {}
  }

  const main = check('mainImage')
  if (main) {
    return main || {}
  }

  return {}
}

const Resources = ({
  className,
  resources = {},
  headline,
  subheadline,
  targetPage,
  linkText,
  narrow,
  isNews,
  paginate = false
}) => {
  const router = useRouter()
  const [page, setPage] = useState(0)

  useEffect(() => {
    setPage(router.query.page ? router.query.page - 1 : 0)
  }, [router.query.page])

  if (!resources || resources.resource?.length === 0) {
    return null
  }

  const pageSize = 12
  const numberOfPages = Math.ceil(resources.resource?.length / pageSize)
  const start = page * pageSize
  const end = start + pageSize

  if (!headline && resources.headline) {
    headline = resources.headline
  }
  if (!subheadline && resources.headline) {
    subheadline = resources.subheadline
  }
  if (resources.cta && resources.cta.length > 0) {
    const ctaItem = PickFirst(resources.cta)
    const ctaTarget = PickFirst(ctaItem.linkedItem)
    targetPage = ctaTarget?.url || '#'
    linkText = ctaItem.text
  }

  const displayResources =
    (paginate ? resources?.resource?.slice(start, end) : resources?.resource) || []

  const cards = displayResources.map((resource) => {
    const tags = FormatTags(resource, ['categories', 'topics'], ['category', 'topic'])
    const image = GetImage(resource)
    return {
      headline: resource.title || resource.headline,
      subheadline:
        resource.shortDescription && resource.shortDescription.split(' ').slice(0, 20).join(' '),
      embed: resource.cardMapEmbed,
      type: 'image',
      image: resource.cardMapEmbed ? null : image.fileName ? [image.fileName] : null,
      imageAlt: image.metadata?.alt || '',
      targetPage: PickFirst(resource.pageBase),
      subtitle: resource.type ? PickFirst(resource.type).name : '',
      tags
    }
  })
  return (
    <>
      <CardContainer
        id="cards"
        cards={cards}
        showLink={false}
        headline={headline}
        subheadline={subheadline}
        className={className}
        targetPage={targetPage}
        linkText={linkText}
        narrow={narrow}
        type={isNews ? 'news' : 'default'}
      />
      {paginate && (
        <Container wide={true}>
          <Pagination
            pageSize={pageSize}
            currentPage={page}
            totalPages={numberOfPages}
            onPage={(idx) => {
              if (typeof window !== 'undefined') {
                window.location.href = `${window.location.href.split('?')[0]}?page=${idx}#cards`
                document.querySelector('#cards').scrollIntoView()
              }
              // router.query.page = idx
              // router.replace(router)
              //setPage(idx - 1)
            }}
          />
        </Container>
      )}
    </>
  )
}

Resources.propTypes = {}

export default Resources
